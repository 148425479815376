import Home from './Home';
import Join from './Join';
import Meeting from './Meeting';

const Views = {
  Home,
  Join,
  Meeting,
};

export default Views;
